<template>
  <div class="section-manage-reports">
    <div class="reports-header">
      <telia-grid>
        <telia-row>
          <telia-col width="12">
            <telia-heading tag="h1" variant="title-300">
              {{ t("MANAGE_REPORTS.TITLE") }}
            </telia-heading>
          </telia-col>
        </telia-row>
      </telia-grid>
    </div>
    <ManageReports />
  </div>
</template>

<script>
import ManageReports from "./components/ManageReports";
import { translateSetup, translateMixin } from "./locale";

export default {
  name: "App",
  mixins: [translateMixin],
  components: {
    ManageReports,
  },
  async created() {
    await translateSetup(["mybusiness"]);
  },
};
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";
.section-manage-reports {
  background-color: $telia-white;
}
.reports-header {
  padding: $telia-spacing-64 0;
  background-color: $telia-gray-100;
}
</style>
