<template>
  <div>
    <telia-grid>
      <telia-row v-if="categoriesState === 'SUCCESS'">
        <telia-col
          width="12"
          width-md="6"
          width-lg="4"
          v-for="mainCategory in mainCategories"
          :key="mainCategory.name"
        >
          <article>
            <telia-heading tag="h2" variant="title-100" class="reports-heading">
              {{ mainCategory.translatedName }}
            </telia-heading>
            <telia-p v-for="subCategory in mainCategory.categories" :key="subCategory.name">
              <div class="reports-subcategory">
                <div>{{ subCategory.translatedName }}</div>
                <span class="reports-subcategory-links">
                  <telia-link
                    @click="downloadReport($event, subCategory.name, 'csv')"
                    :href="getReportUrl(subCategory.name, 'csv')"
                    disable-visited
                    class="reports-link"
                  >
                    <telia-icon name="doc" slot="left" size="sm" />csv</telia-link
                  ><telia-link
                    @click="downloadReport($event, subCategory.name, 'xlsx')"
                    :href="getReportUrl(subCategory.name, 'xlsx')"
                    disable-visited
                    class="reports-link"
                  >
                    <telia-icon name="excel" slot="left" size="sm" />xlsx</telia-link
                  >
                </span>
              </div>
              <telia-divider />
            </telia-p>
          </article>
        </telia-col>
      </telia-row>
      <telia-row v-else-if="categoriesState === 'LOADING'">
        <telia-col width="12" class="content">
          <telia-skeleton class="skeleton-tab" />
        </telia-col>
      </telia-row>
      <telia-row v-else-if="categoriesState === 'EMPTY'">
        <telia-col width="12" class="content">
          <telia-text-spacing class="error__content">
            <img :src="image" class="error__image" alt="" />
            <telia-heading variant="title-100" tag="h2" class="error__heading">
              {{ t("MANAGE_REPORTS.EMPTY") }}
            </telia-heading>
          </telia-text-spacing>
        </telia-col>
      </telia-row>
      <telia-row v-else-if="categoriesState === 'ERROR'">
        <telia-col width="12" class="content">
          <telia-text-spacing class="error__content">
            <img :src="image" class="error__image" alt="" />
            <telia-heading variant="title-300" tag="h2" class="error__heading">
              {{ t("MANAGE_REPORTS.ERROR.HEADING") }}
            </telia-heading>
            <telia-p variant="preamble-100" class="error__message">
              {{ t("MANAGE_REPORTS.ERROR.MESSAGE") }}
            </telia-p>
            <telia-button @click="onReloadClick" variant="primary">
              {{ t("MANAGE_REPORTS.ERROR.RELOAD_PAGE") }}
            </telia-button>
          </telia-text-spacing>
        </telia-col>
      </telia-row>
    </telia-grid>
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";
import STATE from "../state.enum.ts";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { getCategories } from "../services/report-categories-service";
import { currentLanguage } from "@telia/b2b-i18n";
import cloudsIcon from "../images/TC_Icon_Clouds.svg";
import satelliteIcon from "../images/TC_Icon_Satellite_Dish.svg";
import { logError } from "@telia/b2x-logging";

export default {
  mixins: [translateMixin],
  data: () => ({
    categoriesState: STATE.LOADING,
    mainCategories: [],
    scopeId: undefined,
  }),
  async created() {
    await translateSetup(["mybusiness"]);
    this.fetchCategories(false);
  },
  methods: {
    getReportUrl(productCategory, type) {
      return `/.api/corp-installedbase-reports/scope/${encodeURIComponent(
        this.scopeId
      )}/reports/${encodeURIComponent(productCategory)}.${encodeURIComponent(
        type
      )}?language=${encodeURIComponent(currentLanguage())}`;
    },
    async downloadReport(event, productCategory, type) {
      event.preventDefault();
      await analytics.trackEvent(
        analytics.category.REPORTS,
        analytics.action.DOWNLOAD,
        `Product category = ${productCategory}, File type = ${type}`
      );

      window.location.assign(this.getReportUrl(productCategory, type));
    },
    async fetchCategories(retry) {
      this.categoriesState = STATE.LOADING;
      try {
        this.scopeId = await getScopeIdOrThrow();
        this.mainCategories = await getCategories(this.scopeId);
        this.categoriesState = this.mainCategories.length > 0 ? STATE.SUCCESS : STATE.EMPTY;
      } catch (error) {
        if (retry) {
          logError("b2b-manage-reports", "Failed to get scope or get categories (user retried)");
        } else {
          logError("b2b-manage-reports", "Failed to get scope or get categories (first attempt)");
        }
        this.categoriesState = STATE.ERROR;
      }
    },
    onReloadClick() {
      this.fetchCategories(true);
    },
  },
  computed: {
    image() {
      return this.categoriesState == STATE.EMPTY ? cloudsIcon : satelliteIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

telia-grid {
  margin-bottom: $telia-spacing-96;
  > * {
    min-height: $telia-spacing-96;
  }
}

.content {
  height: 35.4rem;
  margin-top: $telia-spacing-48;
}

.skeleton-tab {
  height: 100%;
  width: 100%;
  margin: 0 $telia-spacing-4;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.error {
  margin: auto;
  padding: $telia-spacing-24 0;
  text-align: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    max-width: calc(#{$telia-spacing-16} * 10);
    margin-bottom: $telia-spacing-8;
  }
}

telia-heading.reports-heading {
  padding: $telia-spacing-64 0 $telia-spacing-12 0;
}

.reports-subcategory {
  padding: $telia-spacing-12 0;
}

.reports-subcategory-links {
  white-space: nowrap;
}

telia-link.reports-link {
  padding: $telia-spacing-12 0 $telia-spacing-12 $telia-spacing-12;
  white-space: nowrap;
}

telia-icon {
  vertical-align: middle;
  margin-right: $telia-spacing-8;
  margin-bottom: 2px;
}
</style>
