import type { OverviewResultUI } from "@telia/b2b-rest-client/dist/corp-installedbase-overview/models/OverviewResultUI";
import type { CategoryMainUI } from "@telia/b2b-rest-client/dist/corp-installedbase-overview/models/CategoryMainUI";
import type { CategoryUI } from "@telia/b2b-rest-client/dist/corp-installedbase-overview/models/CategoryUI";

import {
  MainCategory,
  CategoryId,
  getMainCategories,
  getTranslatedMainCategoryDisplayName,
  getTranslatedSubCategoryDisplayName,
  compareSubCategory,
} from "@telia/b2b-product-categories-lib";

export declare type MappedMainCategory = {
  name: keyof typeof MainCategory;
  translatedName: string;
  categories: Array<MappedSubCategory>;
};

declare type MappedSubCategory = {
  name: CategoryId;
  translatedName: string;
};

export const mapCategories = (overview: OverviewResultUI): Array<MappedMainCategory> =>
  (
    getMainCategories(false)
      // take the categories from backend and put them in correct order (or undefined, if BE didn't give us the main category now)
      .map(
        (sortedMainCategoryDefinition: {
          name: string;
          url: string;
        }): CategoryMainUI | undefined => {
          return (overview.mainCategories ?? []).find(
            (mainCategory) => mainCategory.name === sortedMainCategoryDefinition.name
          );
        }
      )
      // remove the categories that backend didn't give us
      .filter(
        (mainCategory: CategoryMainUI | undefined): boolean => !!mainCategory
      ) as Array<CategoryMainUI>
  )

    .map((mainCategory: CategoryMainUI): MappedMainCategory => {
      if (!mainCategory.name || !MainCategory[mainCategory.name]) {
        // this will only happen if there is a mismatch between MainCategory and getMainCategories
        // or if getMainCategories returns one with empty name
        throw new Error("Main category name is missing or not valid");
      } else {
        const safeMainCategoryName = mainCategory.name as keyof typeof MainCategory;
        return {
          // subCategories: filter -> translate -> sort
          name: safeMainCategoryName,
          translatedName: getTranslatedMainCategoryDisplayName(safeMainCategoryName),
          categories: (mainCategory.categories ?? [])
            // add translatedName to each remaining subcategory
            .map(
              (subCategory: CategoryUI): MappedSubCategory => ({
                name: subCategory.name ?? "",
                translatedName: getTranslatedSubCategoryDisplayName(subCategory.name ?? ""),
              })
            )
            // sort the remaining subcategories based on translatedName
            .sort((firstSubCategory: MappedSubCategory, secondSubCategory: MappedSubCategory) =>
              compareSubCategory(
                firstSubCategory.name,
                secondSubCategory.name,
                firstSubCategory.translatedName,
                secondSubCategory.translatedName
              )
            ),
        };
      }
    })
    .filter(
      // keep only main categories that have remaining subcategories
      (mainCategory: MappedMainCategory): boolean => mainCategory.categories.length > 0
    );
